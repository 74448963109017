<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'quotation-create create-form'"
    v-if="getPermission('customer:create')"
  >
    <template v-slot:body>
      <div class="bg-white px-8">
        <v-form
          ref="createQuotationForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="validateJob()"
        >
          <v-row>
            <v-col md="12">
              <div class="form-action">
                <div class="form-title">
                  Rental for

                  <!-- <span>
                <v-autocomplete
                      hide-details
                      class="form-title-link cursor-pointer"
                      id="customer-equipment"
                      :items="customerList"
                      dense
                      flat
                      filled
                      label="Customer"
                      :rules="[
                        validateRules.required(
                          dbQuotation.customer,
                          'customer'
                        ),
                      ]"
                      item-color="cyan"
                      color="cyan"
                      solo
                      v-model.trim="dbQuotation.customer"
                      item-text="display_name"
                      item-value="id"
                      v-on:change="selectCustomer()"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Customer(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="
                              $assetAPIURL(
                                item.profile_logo
                              )
                            "
                            aspect-ratio="1"
                            class="margin-auto"
                            transition="fade-transition"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{
                              item.display_name
                            }}</v-list-item-title
                          >
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{
                              item.company_name
                            }}</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action
                          v-if="item.default_person"
                          class="align-self-center"
                        >
                          <v-list-item-subtitle
                            class="text-lowercase pb-2 font-weight-500 font-size-14"
                            >{{
                              item.default_person
                                .primary_email
                            }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            class="text-lowercase font-weight-500 font-size-14"
                            >{{
                              item.default_person
                                .primary_phone
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-action>
                      </template>
                    </v-autocomplete>
                </span> -->

                  <span
                    class="form-title-link cursor-pointer"
                    v-on:click="openDialog('customer')"
                  >
                    <template v-if="vCustomerId">{{
                      vCustomer.display_name
                    }}</template>
                    <template v-else>
                      Select a Customer
                      <v-icon large color="cyan"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </template>
                  </span>
                </div>
                <div>
                  <v-btn
                    :disabled="formLoading"
                    v-on:click="goBack"
                    class="custom-bold-button custom-grey-border"
                    depressed
                    >Cancel</v-btn
                  >
                  <v-btn
                    class="custom-bold-button ml-4"
                    depressed
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    v-on:click="validateJob()"
                    color="cyan white--text"
                    >Save</v-btn
                  >
                </div>
              </div>
            </v-col>
            <v-col md="7">
              <label for="visit-title" class="required">Rental Title</label>
              <v-text-field
                id="visit-title"
                dense
                filled
                placeholder="Title"
                solo
                flat
                v-model="dbQuotation.title"
                color="cyan"
                :maxlength="250"
                :rules="[validateRules.required(dbQuotation.title, 'Title')]"
              />
              <v-textarea
                auto-grow
                dense
                filled
                color="cyan"
                v-model="dbQuotation.description"
                placeholder="Comments"
                solo
                flat
                row-height="25"
                :rules="[
                  validateRules.minLength(
                    dbQuotation.description,
                    'Description',
                    1
                  ),
                  validateRules.maxLength(
                    dbQuotation.description,
                    'Description',
                    1024
                  ),
                ]"
                v-on:keypress="(e) => manageLimitdescr(e)"
                v-on:paste="(e) => onPaste(e)"
              />
              <v-col md="12" class="text-right"
                >{{
                  dbQuotation.description ? dbQuotation.description.length : 0
                }}/200</v-col
              >
              <v-row>
                <v-col md="6">
                  <v-layout class="align-center">
                    <v-flex md3
                      ><label class="my-0">Billing Location</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        v-on:click="openDialog('billing')"
                        small
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>
                  <template v-if="vBilling?.id">
                    <p class="mb-0">{{ vBilling.property_address }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select billing location</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>
                  <v-layout class="mt-3 align-center">
                    <v-flex md3
                      ><label class="my-0">Contact Details</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('billing-contact')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>
                  <template v-if="vBillingContact?.id">
                    <p class="mb-0">{{ vBillingContact.display_name }}</p>
                    <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                    <p class="mb-0">{{ vBillingContact.primary_email }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select contact person</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>
                  <v-layout class="mt-3">
                    <v-flex md4
                      ><label class="my-0">Email Notification </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="dbQuotation.notify_billing_contact_person"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                        :disabled="duplicateId > 0 ? true : false"
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="6">
                  <v-layout class="align-center">
                    <v-flex md3
                      ><label class="my-0">Site Location</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('property')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vProperty?.id">
                    <p class="mb-0">{{ vProperty.property_address }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select service location</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>

                  <v-layout class="mt-3 align-center">
                    <v-flex md3
                      ><label class="my-0">Contact Details</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('property-contact')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vPropertyContact?.id">
                    <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                    <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                    <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select contact person</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>

                  <v-layout class="mt-3">
                    <v-flex md4
                      ><label class="my-0">Email Notification </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="dbQuotation.notify_property_contact_person"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                        :disabled="duplicateId > 0 ? true : false"
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5">
              <table width="100%" style="table-layout: fixed">
                <tr>
                  <td width="20%">
                    <label for="quotation-id">Rental #</label>
                  </td>
                  <td>
                    <div class="form-title">
                      {{ dbQuotation.barcode }}
                    </div>
                    <!-- <v-text-field
                    id="quotation-id"
                    dense
                    filled
                    readonly
                    hide-details
                    placeholder="Sell #"
                    solo
                    flat
                    :value="dbQuotation.barcode"
                    color="cyan"
                  /> -->
                  </td>
                </tr>
                <!-- <tr>
                <td>
                  <label for="quotation-rate">Rate opportunity</label>
                </td>
                <td>
                  <v-rating
                    id="quotation-rate"
                    v-model.trim="dbQuotation.rating"
                    background-color="orange lighten-3"
                    color="orange"
                  ></v-rating>
                </td>
              </tr> -->
                <tr>
                  <td>
                    <label for="month">Duration Type</label>
                  </td>
                  <td>
                    <v-select
                      :disabled="editId && onEditIsSubRentAvail ? true : false"
                      :items="durationTypes"
                      dense
                      filled
                      v-model.trim="dbQuotation.duration_type"
                      solo
                      flat
                      class="pl-2 pr-2 width-100"
                      label="Duration Type"
                      color="cyan"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      v-on:change="(e) => UpdateEndDate(e, 'rental')"
                    ></v-select>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label for="month">Duration</label>
                  </td>
                  <td>
                    <v-text-field
                      id="month"
                      type="number"
                      v-model.trim="dbQuotation.month"
                      dense
                      filled
                      label="Month"
                      solo
                      flat
                      color="cyan"
                      class="pr-2 width-100"
                      :min="1"
                      oninput="if(this.value < 0) this.value = 0;"
                      v-on:keyup="(e) => UpdateEndDate(e, 'rental')"
                    ></v-text-field>
                    <!-- v-on:keyup="UpdateEndDate(this.value,'rental')"-->
                  </td>
                </tr>

                <tr>
                  <td>
                    <label for="start-date" class="required">Start Date</label>
                  </td>
                  <td>
                    <date-picker
                      key="start-date"
                      id="start-date"
                      :disabled="
                        dbQuotation.check_sub_rent && editId && !duplicateId
                          ? true
                          : false
                      "
                      placeholder="Start Date"
                      v-model="dbQuotation.start_date"
                      v-on:change="(e) => UpdateEndDate(e, 'rental')"
                      :rules="[
                        validateRules.required(
                          dbQuotation.start_date,
                          'Start Date'
                        ),
                      ]"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label for="end-date">Cease Date</label>
                  </td>
                  <td>
                    <date-picker
                      key="end-date"
                      id="end-date"
                      :disabled="
                        dbQuotation.check_sub_rent && editId && !duplicateId
                          ? true
                          : false
                      "
                      :min-date="dbQuotation.start_date"
                      placeholder="Cease Date"
                      v-model="dbQuotation.cease_date"
                    />
                    <!-- <template v-if="dbQuotation?.cease_date">
                      {{ formatDate(dbQuotation?.cease_date) }}
                    </template>
                    <template v-else>
                      <em>no cease date</em>
                    </template> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="your-ref">Billed (Invoice)</label>
                  </td>
                  <td>
                    <v-text-field
                      id="your-ref"
                      dense
                      filled
                      v-model="dbQuotation.billed_invoice"
                      hide-details
                      placeholder="Billed (Invoice)"
                      solo
                      flat
                      color="cyan"
                      :maxlength="250"
                      :disabled="duplicateId > 0 ? true : false"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="your-ref">Billed (Credit Note)</label>
                  </td>
                  <td>
                    <v-text-field
                      id="your-ref1"
                      dense
                      filled
                      v-model="dbQuotation.billed_credit_note"
                      hide-details
                      placeholder="Billed (Credit Note)"
                      solo
                      flat
                      color="cyan"
                      :maxlength="250"
                      :disabled="duplicateId > 0 ? true : false"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="your-ref">Purchase Order No.</label>
                  </td>
                  <td>
                    <v-text-field
                      id="your-ref2"
                      dense
                      filled
                      v-model="dbQuotation.purchase_order_no"
                      hide-details
                      placeholder="Purchase Order No."
                      solo
                      flat
                      color="cyan"
                      :maxlength="250"
                      :disabled="duplicateId > 0 ? true : false"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="sale-by">Rental by</label>
                  </td>
                  <td>
                    <v-autocomplete
                      id="sale-by"
                      dense
                      v-model="dbQuotation.sale_by"
                      item-text="display_name"
                      item-color="cyan"
                      item-value="id"
                      filled
                      :items="userList"
                      hide-details
                      placeholder="Sale by"
                      solo
                      flat
                      color="cyan"
                      :disabled="duplicateId > 0 ? true : false"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="quote-by">Prepared by</label>
                  </td>
                  <td>
                    <v-autocomplete
                      id="quote-by"
                      dense
                      readonly
                      v-model="dbQuotation.quote_by"
                      item-text="display_name"
                      item-color="cyan"
                      item-value="id"
                      :items="userList"
                      filled
                      hide-details
                      placeholder="Prepared by"
                      solo
                      flat
                      color="cyan"
                    />
                  </td>
                </tr>
              </table>
            </v-col>

            <v-col cols="12" ref="qtNote">
              <v-card flat class="custom-grey-border remove-border-radius">
                <v-card-title class="headline grey lighten-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    SUB-RENT INFORMATION
                  </h3>
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <v-layout>
                    <label>Is Sub-Rent Available? </label>
                    <v-switch
                      class="ml-4"
                      color="cyan"
                      dense
                      inset
                      hide-details
                      v-model="dbQuotation.check_sub_rent"
                      :disabled="duplicateId > 0 ? true : false"
                    ></v-switch>
                  </v-layout>

                  <template v-if="dbQuotation?.check_sub_rent">
                    <v-row
                      v-for="(row, index) in sub_rents"
                      :key="`prev-subrent-${index}`"
                    >
                      <v-col sm="12">
                        <v-layout>
                          <v-flex class="mr-4" md4>
                            <label
                              for="month2"
                              class="font-size-16 font-weight-600 pl-4"
                              >Duration Type</label
                            >
                            <br />
                            <v-select
                              :disabled="
                                editId && onEditIsSubRentAvail ? true : false
                              "
                              :items="durationTypes"
                              dense
                              filled
                              v-model.trim="row.duration_type"
                              solo
                              flat
                              class="pl-2 pr-2 width-100"
                              label="Duration Type"
                              color="cyan"
                              item-text="text"
                              item-value="value"
                              item-color="cyan"
                              v-on:change="
                                (e) => UpdateEndDate(e, 'sub-rental', index)
                              "
                            ></v-select>
                          </v-flex>
                          <v-flex class="mr-4" md4>
                            <label class="font-size-16 font-weight-600 required"
                              >Duration</label
                            >

                            <v-text-field
                              :disabled="
                                editId && onEditIsSubRentAvail ? true : false
                              "
                              id="month"
                              type="number"
                              v-model.trim="row.month"
                              dense
                              filled
                              label="Month"
                              solo
                              flat
                              color="cyan"
                              class="pr-2 width-100"
                              :min="1"
                              oninput="if(this.value < 0) this.value = 0;"
                              v-on:keyup="
                                (e) => UpdateEndDate(e, 'sub-rental', index)
                              "
                              :rules="[
                                validateRules.required(
                                  row.month,
                                  ' Sub Rent Month'
                                ),
                              ]"
                            ></v-text-field>
                          </v-flex>

                          <v-flex class="mr-4" md4>
                            <label class="font-size-16 font-weight-600 required"
                              >Start Date</label
                            >
                            <date-picker
                              :disabled="
                                editId && onEditIsSubRentAvail && !duplicateId
                                  ? true
                                  : false
                              "
                              key="start-date1"
                              id="start-date1"
                              :min-date="dbQuotation.start_date"
                              :max-date="dbQuotation.cease_date"
                              placeholder="Start Date"
                              v-model="row.start_date"
                              v-on:change="
                                (e) => UpdateEndDate(e, 'sub-rental', index)
                              "
                              :rules="[
                                validateRules.required(
                                  row.start_date,
                                  'Sub Rent Start Date'
                                ),
                              ]"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout class="mt-2">
                          <v-flex class="mr-4" md4>
                            <label
                              class="font-size-16 font-weight-600 required pl-4"
                              >Off-Hire</label
                            >
                            <br />

                            <!-- <template v-if="row?.off_hire">
                              <span class="black--text pl-4">{{
                                formatDate(row?.off_hire)
                              }}</span>
                            </template>
                            <template v-else>
                              <em class="pl-4">no off hire</em>
                            </template> -->

                            <date-picker
                              v-if="true"
                              :disabled="
                                editId && onEditIsSubRentAvail && !duplicateId
                                  ? true
                                  : false
                              "
                              key="off-hire"
                              id="off-hire"
                              :min-date="row.start_date"
                              :max-date="dbQuotation.cease_date"
                              placeholder="Off-Hire"
                              v-model="row.off_hire"
                            />
                          </v-flex>
                          <v-flex class="mr-4" md4>
                            <label class="font-size-16 font-weight-600"
                              >Customer Name</label
                            >
                            <v-text-field
                              class="pr-2 width-100"
                              id="sub_rent_customer_name"
                              dense
                              filled
                              v-model="row.customer_name"
                              hide-details
                              placeholder="Customer Name"
                              solo
                              flat
                              color="cyan"
                              :maxlength="250"
                              :disabled="
                                duplicateId > 0 ||
                                (editId && onEditIsSubRentAvail)
                                  ? true
                                  : false
                              "
                            />
                          </v-flex>
                          <v-flex class="mr-4" md4>
                            <label class="font-size-16 font-weight-600"
                              >Inv No.
                            </label>
                            <v-text-field
                              class="pr-2 width-100"
                              id="sub_rent_invoice_no"
                              dense
                              filled
                              v-model="row.invoice_no"
                              hide-details
                              placeholder="Inv No."
                              solo
                              flat
                              color="cyan"
                              :maxlength="250"
                              :disabled="
                                duplicateId > 0 ||
                                (editId && onEditIsSubRentAvail)
                                  ? true
                                  : false
                              "
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout class="mt-2">
                          <v-col
                            md="12"
                            class="gray-background"
                            style="
                              border-bottom: 1px solid lightgray !important;
                            "
                          ></v-col>
                        </v-layout>
                        <v-layout class="mt-2">
                          <v-flex class="mr-4" md4>
                            <label
                              class="font-size-16 font-weight-600 required"
                            >
                              Site Location Name
                            </label>
                            <v-text-field
                              :disabled="
                                editId && onEditIsSubRentAvail ? true : false
                              "
                              v-model.trim="row.site_location_name"
                              :rules="
                                !editId
                                  ? [
                                      validateRules.maxLength(
                                        row.site_location_name,
                                        'Site Location Name',
                                        100
                                      ),
                                      validateRules.required(
                                        row.site_location_name,
                                        'Site Location Name'
                                      ),
                                    ]
                                  : []
                              "
                              dense
                              filled
                              label="Site Location Name."
                              solo
                              flat
                              color="cyan"
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="mr-4" md4>
                            <label class="font-size-16 font-weight-600"
                              >Country
                            </label>
                            <v-autocomplete
                              :disabled="
                                editId && onEditIsSubRentAvail ? true : false
                              "
                              v-model.trim="row.country"
                              :items="countryList"
                              hide-details
                              flat
                              :rules="[
                                validateRules.required(row.country, 'Country'),
                              ]"
                              solo
                              dense
                              item-value="value"
                              item-text="text"
                              label="Country"
                              color="cyan"
                              item-color="cyan"
                              v-on:change="countryCheck($event)"
                              class="pt-0 mt-0"
                            >
                              <template v-slot:item="{ item }">
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="text-capitalize font-weight-500 font-size-16"
                                    >{{ item.text }}</v-list-item-title
                                  >
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </v-flex>
                          <v-flex class="mr-4" md4>
                            <label class="font-size-16 font-weight-500"
                              >Search Address
                            </label>
                            <SearchAddress
                              variant="outlined"
                              density="compact"
                              hide-details
                              :country="row.country"
                              :country-code="country_code"
                              @update:address="(data) => setAddress(data)"
                              :label="
                                row.country == 'singapore'
                                  ? 'Search Address'
                                  : 'Search Address'
                              "
                              :placeholder="
                                row.country == 'singapore'
                                  ? 'Search Address'
                                  : 'Search Address'
                              "
                            />
                          </v-flex>
                        </v-layout>

                        <v-layout class="mt-2">
                          <v-flex class="mr-4" md4>
                            <label class="font-size-16 font-weight-600 required"
                              >Postal Code</label
                            >
                            <v-text-field
                              :disabled="
                                editId && onEditIsSubRentAvail ? true : false
                              "
                              v-model.trim="row.postal_code"
                              :rules="
                                !editId
                                  ? [
                                      validateRules.maxLength(
                                        row.postal_code,
                                        'Postal Code',
                                        10
                                      ),
                                      validateRules.minLength(
                                        row.postal_code,
                                        'Postal Code',
                                        2
                                      ),
                                      validateRules.required(
                                        row.postal_code,
                                        'Postal Code'
                                      ),
                                    ]
                                  : []
                              "
                              dense
                              filled
                              v-mask="'######'"
                              label="Postal Code"
                              solo
                              flat
                              color="cyan"
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="mr-4" md4>
                            <label class="font-size-16 font-weight-600"
                              >Unit No.</label
                            >
                            <v-text-field
                              :disabled="
                                editId && onEditIsSubRentAvail ? true : false
                              "
                              class="pr-2 width-100"
                              id="unit_no"
                              dense
                              filled
                              v-model="row.unit_no"
                              hide-details
                              placeholder="Unit No."
                              solo
                              flat
                              color="cyan"
                              :maxlength="250"
                            />
                          </v-flex>
                          <v-flex class="mr-4" md4>
                            <label class="font-size-16 font-weight-600"
                              >Address Line 1
                            </label>
                            <v-text-field
                              :disabled="
                                editId && onEditIsSubRentAvail ? true : false
                              "
                              class="pr-2 width-100"
                              id="address_line"
                              dense
                              filled
                              v-model="row.address_line_1"
                              hide-details
                              placeholder="Address Line 1"
                              solo
                              flat
                              color="cyan"
                              :maxlength="250"
                            />
                          </v-flex>
                        </v-layout>

                        <v-layout class="mt-2">
                          <v-flex class="mr-4" md4>
                            <label class="font-size-16 font-weight-600"
                              >Address Line 2
                            </label>
                            <v-text-field
                              :disabled="
                                editId && onEditIsSubRentAvail ? true : false
                              "
                              class="pr-2 width-100"
                              id="address_line2"
                              dense
                              filled
                              v-model="row.address_line_2"
                              hide-details
                              placeholder="Address Line 2"
                              solo
                              flat
                              color="cyan"
                              :maxlength="250"
                            />
                          </v-flex>

                          <!-- <v-flex class="mr-4" md4>
                          <label class="font-size-16 font-weight-600"
                            >Country
                            </label>
                            <v-text-field
                            :disabled="editId && onEditIsSubRentAvail?true:false"
                              v-model.trim="row.country"
                              :rules="[
                                
                                validateRules.maxLength(
                                  row.country,
                                  'Country',
                                  10
                                ),
                              ]"
                              dense
                              filled
                              label="Country"
                              solo
                              flat
                              color="cyan"
                            ></v-text-field>
                        </v-flex> -->
                        </v-layout>

                        <v-layout class="mt-2">
                          <v-flex class="mr-4" md8>
                            <label class="font-size-16 font-weight-600">
                              Remark
                            </label>
                            <v-textarea
                              :disabled="
                                editId && onEditIsSubRentAvail ? true : false
                              "
                              auto-grow
                              dense
                              filled
                              color="cyan"
                              v-model="row.remark"
                              placeholder="Remark"
                              solo
                              flat
                              row-height="25"
                              :maxlength="250"
                            />
                          </v-flex>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" ref="qtLineItem">
              <v-card flat class="custom-grey-border remove-border-radius">
                <v-card-title class="headline grey lighten-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Line Items
                  </h3>
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <line-item
                    :rental-start-date="dbQuotation.start_date"
                    :rental-end-date="dbQuotation.cease_date"
                    module-type="rental"
                    :duplicate-id="duplicateId"
                  />
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" ref="qtNote">
              <v-card flat class="custom-grey-border remove-border-radius">
                <v-card-title class="headline grey lighten-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Term Conditions & ATTACHMENTS
                  </h3>
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <v-layout>
                    <v-flex class="mr-4" md12>
                      <label class="font-size-16 font-weight-600"
                        >Term Conditions</label
                      >
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        label="Term Conditions"
                        solo
                        flat
                        class="my-2"
                        item-color="cyan"
                        item-text="label"
                        item-value="id"
                        hide-details
                        :disabled="
                          formLoading || (duplicateId > 0 ? true : false)
                        "
                        :loading="formLoading"
                        :items="adminNoteList"
                        v-model="dbQuotation.admin_note_id"
                        v-on:change="update_admin_note()"
                      >
                      </v-autocomplete>
                      <tiny-mce
                        :disabled="
                          (formLoading ? true : false) ||
                          (duplicateId > 0 ? true : false)
                        "
                        v-model="dbQuotation.admin_note"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md12 class="mt-4">
                      <label class="font-size-16 font-weight-600"
                        >Remark
                        <!-- <span class="text--secondary font-weight-500"
                        >(visible on PDF)</span
                      > -->
                      </label>
                      <!-- <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      label="Remark"
                      solo
                      flat
                      class="my-2"
                      item-color="cyan"
                      item-text="label"
                      item-value="id"
                      hide-details
                      :disabled="formLoading"
                      :loading="formLoading"
                      :items="clientNoteList"
                      v-model="dbQuotation.client_note_id"
                      v-on:change="update_client_note()"
                    >
                    </v-autocomplete> -->
                      <tiny-mce
                        :disabled="
                          formLoading || (duplicateId > 0 ? true : false)
                        "
                        v-model="dbQuotation.remark"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-4">
                    <v-flex md6 class="mr-4">
                      <label class="font-size-16 font-weight-600"
                        >Attachments</label
                      >
                      <div class="ml-1">
                        Allowed Extensions:
                        doc,docx,txt,text,rtf,xsl,jpeg,png,gif,zip,rar,pdf,xlsx,word,jpg
                        <br />
                        Max files: 5, Max Size: 5MB
                      </div>
                      <table width="100%">
                        <tbody>
                          <tr
                            v-for="(row, index) in dbQuotation.attachments"
                            :key="`prev-attach-${index}`"
                          >
                            <td width="50%" class="pr-2 py-2">
                              <v-text-field
                                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                readonly
                                color="cyan"
                                v-model="row.name"
                                hide-details
                              ></v-text-field>
                            </td>
                            <td width="50%" class="pl-2 py-2">
                              <v-text-field
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                color="cyan"
                                v-model="row.name"
                                hide-details
                                class="has-delete-outer-icon"
                                append-outer-icon="mdi-delete"
                                v-on:click:append-outer="
                                  remove_row('dbQuotation.attachments', index)
                                "
                                :suffix="`.${row.ext}`"
                              ></v-text-field>
                            </td>
                          </tr>

                          <tr v-for="(row, index) in attachments" :key="index">
                            <td width="50%" class="pr-2 py-2">
                              <v-file-input
                                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                placeholder="Click here to select file"
                                label="Click here to select file"
                                prepend-icon=""
                                color="cyan"
                                solo
                                flat
                                v-model="row.accepted_file"
                                dense
                                v-on:change="update_file_name(index)"
                                hide-details
                              ></v-file-input>
                            </td>
                            <td width="50%" class="pl-2 py-2">
                              <v-text-field
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                color="cyan"
                                v-model="row.file_name"
                                hide-details
                                class="has-delete-outer-icon"
                                append-outer-icon="mdi-delete"
                                v-on:click:append-outer="
                                  remove_row('attachments', index)
                                "
                                :suffix="get_file_extension(row.file_type)"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr>
                            <td height="10px" colspan="2"></td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <v-btn
                                color="cyan white--text"
                                small
                                v-on:click="add_attachment_row()"
                                class="custom-bold-button"
                                v-if="attachments.length < 5"
                                >Add More...</v-btn
                              >
                            </td>
                          </tr>
                          <tr>
                            <td height="10px" colspan="2"></td>
                          </tr>
                        </tbody>
                      </table>
                    </v-flex>
                    <v-flex md6 class="ml-4"> </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <customer-select
          :dialog="customerDialog"
          v-on:close="customerDialog = false"
        />
        <person-select
          :dialog="contactDialog"
          v-on:close="contactDialog = false"
          :type="contactType"
        />
        <address-select
          :dialog="propertyDialog"
          v-on:close="propertyDialog = false"
          :type="propertyType"
          :label="propertyLabel"
        />
      </div>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/components/QDatePicker.vue";
import LineItemV2 from "@/view/pages/partials/Line-Item-V3.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import moment from "moment-timezone";
import ObjectPath from "object-path";
import MomentJS from "moment-timezone";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import {
  RESET_STATE,
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
} from "@/core/services/store/visit.module";
import { UPLOAD, QUERY, POST, PUT } from "@/core/services/store/request.module";
import {
  SET_UOM,
  SET_GST,
  SET_EDIT_LINE_ITEM,
  SET_EDIT_CALCULATION,
  CLEAR_LINE_ITEM,
} from "@/core/services/store/line.item.module";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import KTCookie from "@/assets/js/components/cookie";
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import SearchAddress from "@/view/components/Search-Address";

export default {
  name: "create-quotation",
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      routePreventDialog: false,
      isCustomerCreated: false,
      country_code: "sg",
      onEditIsSubRentAvail: false,
      customerList: [],
      formLoading: false,
      formValid: true,
      duplicateId: 0,
      editId: 0,
      dbQuotation: {
        duration_type: "month",
        check_sub_rent: false,
        customer: 0,
        barcode: null,
        title: null,
        description: null,
        billing_contact_person: false,
        property_contact_person: false,
        rating: 1,
        date: null,
        sale_by: null,
        customer_ref: null,
        your_ref: null,
        quote_by: null,
        admin_note_id: 0,
        admin_note: null,
        client_note_id: 0,
        client_note: null,
        attachments: [],
        start_date: null,
        cease_date: null,
        billed_invoice: null,
        billed_credit_note: null,
        purchase_order_no: null,
        sub_rent_customer_name: null,
        sub_rent_start_date: null,
        sub_rent_off_hire: null,
        sub_rent_invoice_no: null,
        sub_rent_remark: null,
        month: 1,
        sub_rent_month: 1,
        duplicate: 0,
        notify_billing_contact_person: 0,
        notify_property_contact_person: 0,
      },
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      sub_rents: [
        {
          customer_name: null,
          start_date: null,
          off_hire: null,
          invoice_no: null,
          remark: null,
          month: 1,
          site_location_name: null,
          unit_no: null,
          address_line_1: null,
          address_line_2: null,
          postal_code: null,
          country: "singapore",
          duration_type: "month",
        },
      ],
      adminNoteList: [],
      clientNoteList: [],
      userList: [],
      todayDate: null,
      customerDialog: false,
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      contactType: null,
      contactDialog: false,
      countryList: [
        {
          text: "Singapore",
          value: "singapore",
          country_code: "sg",
        },
        {
          text: "Malaysia",
          value: "malaysia",
          country_code: "my",
        },
        {
          text: "Myanmar",
          value: "myanmar",
          country_code: "mm",
        },
        {
          text: "India",
          value: "india",
          country_code: "in",
        },
        {
          text: "China",
          value: "china",
          country_code: "cn",
        },
        {
          text: "Australia",
          value: "australia",
          country_code: "at",
        },
        {
          text: "American",
          value: "american",
          country_code: "as",
        },
        {
          text: "UK",
          value: "uk",
          country_code: "gb",
        },
        {
          text: "Canada",
          value: "canada",
          country_code: "ca",
        },
      ],
      durationTypes: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
    };
  },
  components: {
    SearchAddress,
    "customer-select": CustomerSelect,
    "person-select": PersonSelect,
    "address-select": AddressSelect,
    "line-item": LineItemV2,
    "date-picker": DatePicker,
    "tiny-mce": TinyMCE,
    CreateUpdateTemplate,
  },
  methods: {
    countryCheck(data) {
      let code = "";
      code = this.lodash.find(this.countryList, function (row) {
        if (row.value == data) {
          return row.country_code;
        } else {
          return false;
        }
      });
      this.country_code = code.country_code;

      this.sub_rents[0].postal_code = null;
      this.sub_rents[0].address_line_1 = null;
      this.sub_rents[0].address_line_2 = null;
      this.sub_rents[0].unit_no = null;
      //  this.propertyAddress.longitude = null;
      //this.propertyAddress.latitude = null;
    },
    setAddress(e) {
      this.sub_rents[0].postal_code = e.postal_code;
      this.sub_rents[0].address_line_1 = e.address1;
      this.sub_rents[0].address_line_2 = e.address2;
      this.sub_rents[0].unit_no = e.unit_number;
      // this.row.longitude = e.longitude;
      // this.row.latitude = e.latitude;
    },

    addOneDate(date = "") {
      if (!date) return null;

      let date1 = MomentJS(date, "YYYY-MM-DD").add(1, "days");
      if (date1) {
        date1 = date1.format("YYYY-MM-DD");
      }
      //console.log(date1);
      return date1;
    },
    subOneDate(date = "") {
      if (!date) return null;

      let date1 = MomentJS(date, "YYYY-MM-DD").subtract(1, "days");
      if (date1) {
        date1 = date1.format("YYYY-MM-DD");
      }
      return date1;
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.dbQuotation.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.dbQuotation.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.dbQuotation.description &&
        this.dbQuotation.description.length > 199
      ) {
        e.preventDefault();
      }
    },
    // showHideSubRent(){
    //   console.log(this.dbQuotation.check_sub_rent);
    //   // if(this.dbQuotation.check_sub_rent)
    //   // {
    //   //   this.dbQuotation.check_sub_rent = false;
    //   // }else{this.dbQuotation.check_sub_rent = true;}
    // },
    UpdateEndDate(value = 0, type = "", index = 0) {
      if (!value) return false;

      let startDate = "";
      let duration = 1;
      let duration_type = "month";

      if (type == "rental") {
        duration = this.dbQuotation.month;
        startDate = this.dbQuotation.start_date;
        duration_type = this.dbQuotation.duration_type;
      }
      if (type == "sub-rental") {
        duration = this.sub_rents[index].month;
        startDate = this.sub_rents[index].start_date;
        duration_type = this.sub_rents[index]?.duration_type;
      }

      if (startDate) {
        // let date = MomentJS(startDate, "YYYY-MM-DD").add(
        //   duration,
        //         "M"
        //       );
        let date = "";
        switch (duration_type) {
          case "day":
            date = moment(startDate, "YYYY-MM-DD").add(duration, "d");
            break;
          case "month":
            date = moment(startDate, "YYYY-MM-DD").add(duration, "M");
            break;
          case "year":
            date = moment(startDate, "YYYY-MM-DD").add(duration, "y");
            break;
        }

        if (date) {
          date = date.format("YYYY-MM-DD");
        }
        if (type == "rental") {
          this.dbQuotation.cease_date = date;
        }
        if (type == "sub-rental") {
          //console.log(date,'start date');
          //console.log(this.dbQuotation.cease_date);

          // if(date>this.dbQuotation.cease_date){
          //   ErrorEventBus.$emit("update:error", "Sub rent end date can not be greater than cease date");
          //   return false;
          // }
          this.sub_rents[index].off_hire = date;
        }
      }
    },

    selectCustomer() {
      const row = this.customerList.find(
        (row) => (row.id = this.dbQuotation.customer)
      );
      if (row) {
        if (this.loading) {
          return false;
        }
        this.loading = true;
        this.$store
          .dispatch(SET_CUSTOMER, row)
          .then(() => {
            //this.$emit("close", true);
            // this.search = null;
            // this.list = [];
          })
          .catch((error) => {
            console.log({ error });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    remove_row(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.add_attachment_row();
      }
    },
    update_admin_note() {
      let output = this.lodash.find(this.adminNoteList, {
        id: this.dbQuotation.admin_note_id,
      });
      if (output) {
        this.dbQuotation.admin_note = output.description;
      }
    },
    update_client_note() {
      let output = this.lodash.find(this.clientNoteList, {
        id: this.dbQuotation.client_note_id,
      });
      if (output) {
        this.dbQuotation.client_note = output.description;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    openDialog(type) {
      if (this.duplicateId) return false;

      this.customerDialog = false;
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.customerDialog = true;
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Service Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    validateJob() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Service Location");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Service Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }

      if (!this.lSelectedLineItem || !this.lSelectedLineItem.length) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      const validateStatus = this.$refs.createQuotationForm.validate();

      const formErrors = this.validateForm(this.$refs.createQuotationForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      const AllItems = this.lSelectedLineItem;
      for (var k = 0; k < AllItems.length; k++) {
        if (!AllItems[k].product) {
          ErrorEventBus.$emit(
            "update:error",
            AllItems[k].type + " title is required."
          );
          return false;
        }
      }

      if (validateStatus) {
        this.onSubmit();
      }
    },

    async onSubmit() {
      const new_attachments = await this.uploadFiles();

      const request = {
        ...this.dbQuotation,
        attachments: [...this.dbQuotation.attachments, ...new_attachments],
        customer: this.vCustomerId,
        property: this.vPropertyId,
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
        "line-item": this.lSelectedLineItem,
        calculation: this.lCalculation,
        sub_rents: this.sub_rents,
      };

      let requestType = POST;
      let requestURL = "rental";

      let quotationId = this.$route?.query?.edit ?? 0;

      if (quotationId) {
        requestType = PUT;
        requestURL = `rental/${quotationId}`;
      }

      this.formLoading = true;

      this.$store
        .dispatch(requestType, { url: requestURL, data: request })
        .then(({ data }) => {
          this.$store.dispatch(RESET_STATE);
          this.$store.dispatch(CLEAR_LINE_ITEM);
          this.isCustomerCreated = true;
          this.$nextTick(() => {
            this.$router.push(
              this.getDefaultRoute("rental.detail", {
                params: { id: data?.id },
              })
            );
          });
        })
        .catch(() => {
          this.formLoading = false;
        });
    },

    get_attributes() {
      this.editId = ObjectPath.get(this.$route, "query.edit");
      if (this.editId) {
        this.editId = parseInt(this.editId);
      }
      this.$store
        .dispatch(QUERY, {
          url: "rental/options",
          data: { id: ObjectPath.get(this.$route, "query.edit") },
        })
        .then((response) => {
          this.dbQuotation.barcode = ObjectPath.get(response, "data.barcode");

          this.customerList = ObjectPath.get(response, "data.customers");
          //  console.log(this.customerList );
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Rental", route: "rental" },
            { title: "Create" },
            { title: this.dbQuotation.barcode },
          ]);

          this.$store.dispatch(
            SET_UOM,
            ObjectPath.get(response, "data.uom_list", [])
          );

          this.$store.dispatch(
            SET_GST,
            ObjectPath.get(response, "data.entity.gstvalue", 0)
          );
          this.adminNoteList = ObjectPath.get(
            response,
            "data.admin_notes_list",
            []
          );
          this.clientNoteList = ObjectPath.get(
            response,
            "data.client_notes_list",
            []
          );

          // let defaultClientObject = this.lodash.find(this.clientNoteList, {
          //   primary: 1,
          // });
          // if (defaultClientObject) {
          //   this.dbQuotation.client_note_id = defaultClientObject.id;
          //   this.dbQuotation.client_note = defaultClientObject.description;
          // }

          let defaultAdminObject = this.lodash.find(this.adminNoteList, {
            primary: 1,
          });
          if (defaultAdminObject) {
            this.dbQuotation.admin_note_id = defaultAdminObject.id;
            this.dbQuotation.admin_note = defaultAdminObject.description;
          }

          this.userList = ObjectPath.get(response, "data.sales_users", []);

          this.dbQuotation.sale_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );

          this.dbQuotation.quote_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );

          let dbQuotation = ObjectPath.get(response, "data.detail");

          if (dbQuotation?.id) {
            if (!this.duplicateId) {
              this.dbQuotation.barcode = dbQuotation?.barcode;
            }
            this.dbQuotation.check_sub_rent = dbQuotation?.check_sub_rent;

            this.onEditIsSubRentAvail = dbQuotation?.check_sub_rent;

            //this.dbQuotation.customer = dbQuotation?.customer;
            this.dbQuotation.title = dbQuotation?.title;
            this.dbQuotation.description = dbQuotation?.description;
            this.dbQuotation.customer_ref = dbQuotation?.customer_ref;
            this.dbQuotation.your_ref = dbQuotation?.your_ref;
            this.dbQuotation.quote_by = dbQuotation?.quote_by;
            this.dbQuotation.sale_by = dbQuotation?.sale_by;
            this.dbQuotation.sale_invoice_no = dbQuotation?.sales_invoice_no;
            this.dbQuotation.sale_agreement = dbQuotation?.sales_agreement;
            this.dbQuotation.remark = dbQuotation?.remark;
            this.dbQuotation.duration_type = dbQuotation?.duration_type;

            this.dbQuotation.date = moment(dbQuotation?.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.rating = dbQuotation?.rating;
            this.dbQuotation.attachments = dbQuotation?.attachments ?? [];

            this.dbQuotation.notify_billing_contact_person =
              dbQuotation?.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              dbQuotation?.notify_property_contact_person;

            this.dbQuotation.client_note_id = dbQuotation?.client_note_id;
            this.dbQuotation.client_note = dbQuotation?.client_note;

            this.dbQuotation.admin_note_id = dbQuotation?.admin_note_id;
            this.dbQuotation.admin_note = dbQuotation?.admin_note;

            //rental details
            this.dbQuotation.start_date = dbQuotation?.start_date;
            this.dbQuotation.cease_date = dbQuotation?.cease_date;

            if (this.duplicateId) {
              this.dbQuotation.start_date = null;
              this.dbQuotation.cease_date = null;
            }

            this.dbQuotation.billed_invoice = dbQuotation?.billed_invoice;
            this.dbQuotation.billed_credit_note =
              dbQuotation?.billed_credit_note;
            this.dbQuotation.purchase_order_no = dbQuotation?.purchase_order_no;
            this.dbQuotation.sub_rent_customer_name =
              dbQuotation?.sub_rent_customer_name;
            this.dbQuotation.sub_rent_start_date =
              dbQuotation?.sub_rent_start_date;
            this.dbQuotation.sub_rent_off_hire = dbQuotation?.sub_rent_off_hire;
            this.dbQuotation.sub_rent_invoice_no =
              dbQuotation?.sub_rent_invoice_no;
            this.dbQuotation.sub_rent_remark = dbQuotation?.sub_rent_remark;
            this.dbQuotation.month = dbQuotation?.month;
            this.dbQuotation.sub_rent_month = dbQuotation?.sub_rent_month;

            //multiple rentals info
            if (dbQuotation.sub_rentals.length > 0) {
              this.sub_rents = dbQuotation.sub_rentals[0];

              if (this.duplicateId) {
                // console.log(this.sub_rents);
                this.sub_rents.id = null;
                this.sub_rents.start_date = null;
                this.sub_rents.off_hire = null;
              }

              this.sub_rents = [this.sub_rents]; //set it to 0th level so even if we have multiple we only show one and accordingly backend is adjusted.
            }
            this.$store.commit(SET_CUSTOMER, dbQuotation?.customer_relation);
            this.$store.commit(SET_PROPERTY, dbQuotation?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              dbQuotation?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, dbQuotation?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              dbQuotation?.billing_contact_person_relation
            );

            this.$store.dispatch(SET_EDIT_CALCULATION, {
              ctx_discount_label: dbQuotation?.ctx_discount_label ?? null,
              ctx_discount_value: dbQuotation?.ctx_discount_value ?? 0,
              ctx_discount_type: dbQuotation?.ctx_discount_type ?? 1,
              discount_type: dbQuotation?.discount_type ?? 1,
              discount_value: dbQuotation?.discount_value ?? 0,
              tax_active: dbQuotation?.tax_active ?? false,
              adjustment: dbQuotation?.adjustment ?? 0,
              show_price: true,
              discount_level: dbQuotation?.discount_level ?? "transaction",
            });

            //set line items empty on duplicate
            var lineItemsToSet = dbQuotation?.line_item ?? [];
            if (this.duplicateId) {
              lineItemsToSet = [];
            }

            this.$store.dispatch(SET_EDIT_LINE_ITEM, lineItemsToSet);

            let BreadcrumbText = "Update";
            if (this.duplicateId) {
              BreadcrumbText = "Create";
            }
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Rental", route: "rental" },
              { title: BreadcrumbText },
              { title: this.dbQuotation.barcode },
            ]);
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          // this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.get_attributes();
    if (ObjectPath.get(this.$route, "query.duplicate")) {
      this.duplicateId = ObjectPath.get(this.$route, "query.duplicate");
      this.duplicateId = parseInt(this.duplicateId);
      this.dbQuotation.duplicate = this.duplicateId;
    }
    this.$store.dispatch(SET_BREADCRUMB, []);
  },

  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.isCustomerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },

  destroyed() {
    this.$store.dispatch(RESET_STATE);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  beforeDestroy() {
    KTCookie.setCookie("kt_aside_toggle_state", "off");
    localStorage.setItem("kt_aside_toggle_state", "off");
  },
  created() {
    this.todayDate = moment().format("YYYY-MM-DD");
    this.dbQuotation.date = this.todayDate;
  },
  beforeCreate() {
    KTCookie.setCookie("kt_aside_toggle_state", "on");
    localStorage.setItem("kt_aside_toggle_state", "on");
    this.$store.dispatch(RESET_STATE);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },

  computed: {
    ...mapGetters([
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "lSelectedLineItem",
      "lCalculation",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.main-create-page .form-title {
  font-size: 24px;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 500;
}
.quotation-create-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;
    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
